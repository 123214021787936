import "./App.css";
import Appbar from "./components/Appbar/Appbar";
import NewSection1 from "./components/NewSection1/NewSection1";
import { Suspense, lazy } from "react";

const Section2 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Section2/Section2")), 1000)
    )
);
const Section3 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Section3/Section3")), 1000)
    )
);
const Section4 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Section4/Section4")), 1000)
    )
);

const Section5 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Section5/Section5")), 1000)
    )
);

const Section6 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Section6/Section6")), 1000)
    )
);
const Section7 = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Section7/Section7")), 1000)
    )
);

const Offers = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./components/Offers/Offers")), 1000)
    )
);

const Inclusion = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./components/Inclusion/Inclusion")),
        1000
      )
    )
);

const DigitalPanties = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./components/DigitalPanties/DigitalPanties")),
        1000
      )
    )
);

const PreciousPanties = lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(
        () => resolve(import("./components/PreciousPanties/PreciousPanties")),
        1000
      )
    )
);
function App() {
  return (
    <div className="App">
      <Appbar />

      <NewSection1 />

      <Suspense fallback={<div />}>
        <Section2 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Offers />
      </Suspense>
      <Suspense fallback={<div />}>
        <Inclusion />
      </Suspense>
      <Suspense fallback={<div />}>
        <DigitalPanties />
      </Suspense>
      <Suspense fallback={<div />}>
        <PreciousPanties />
      </Suspense>
      {/* <Suspense fallback={<div />}>
        <Section3 />
      </Suspense> */}
      <Suspense fallback={<div />}>
        <Section4 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section5 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section6 />
      </Suspense>
      <Suspense fallback={<div />}>
        <Section7 />
      </Suspense>
    </div>
  );
}

export default App;
