import React, { useState } from "react";
import "./Appbar.css";
import list from "./menu.svg";
import close from "./close.svg";
import close2 from "./close2.svg";
import { Squash as Hamburger } from "hamburger-react";
import { slide as Menu } from "react-burger-menu";
import MediaQuery from "react-responsive";
function Appbar() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <div>
        <div
          className={click ? "main-container" : ""}
          onClick={() => Close()}
        />
        <nav
          id="mynav"
          className={colorChange ? "navbar colorChange" : "navbar"}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="nav-container">
            <div>
              <div className="nav__width">
                <a href="/" className="nav-logo">
                  <img
                    className={colorChange ? "bar__logo2" : "bar__logo"}
                    src={
                      colorChange ? "./assets/logoB.svg" : "./assets/logo.svg"
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="nav__insta navSocial"
                      src={
                        colorChange
                          ? "./assets/navbar__insta.svg"
                          : "./assets/nav__insta.svg"
                      }
                      alt=""
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="nav__insta navSocial"
                      src={
                        colorChange
                          ? "./assets/navbar__disc.svg"
                          : "./assets/navbar__discord.svg"
                      }
                      alt=""
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Team"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="nav__twitter navSocial"
                      src={
                        colorChange
                          ? "./assets/navbar__twitter.svg"
                          : "./assets/nav__twitter.svg"
                      }
                      alt=""
                    />
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="nav__insta navSocial"
                      src={
                        colorChange
                          ? "./assets/navbar__mediumB.svg"
                          : "./assets/navbar__medium.svg"
                      }
                      alt=""
                    />
                  </a>
                </li>
                {/* <li
                  className={
                    colorChange ? "nav-item discTop2" : "nav-item discTop"
                  }
                >
                  <a
                    href="#Faq"
                    activeClassName="active"
                    className={colorChange ? "nav-links2" : "nav-links"}
                    onClick={click ? handleClick : null}
                  >
                    JOIN DISCORD
                  </a>
                </li> */}

                <li className="responsive__menu menu_news">NEWSLETTER</li>

                <hr className="responsive__menu__hr"></hr>

                <li className="responsive__menu">
                  <div className="responsive__menu__social">
                    <div>
                      <a href="">
                        <img
                          className="footer__disc socialMenu "
                          src="./assets/menuDisc.svg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div>
                      <a href="">
                        <img
                          className="nav__insta socialMenu socialPaddingMenu"
                          src="./assets/instaB.svg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div>
                      <a href="">
                        <img
                          className="nav__twitter socialMenu socialPaddingMenu"
                          src="./assets/twitterB.svg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div onClick={handleClick}>
              {/* <img
                src={click ? (colorChange ? close2 : close) : list}
                style={{ color: "white" }}
                className={click ? "nav-icon2" : "nav-icon"}
              ></img> */}
              {click ? (
                <Hamburger
                  size={20}
                  color={colorChange ? "#000" : "#fff"}
                  className=""
                />
              ) : (
                <Hamburger
                  size={20}
                  color={colorChange ? "#000" : "#fff"}
                  className=""
                />
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Appbar;
