import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Countdown from "../Section1/Countdown";
import "./NewSection1.css";
import panty from "./section1_panty.png";
import pantyR from "./responsive_panty.png";
import MediaQuery from "react-responsive";

function NewSection1() {
  // useEffect(() => {
  //   setTimeout(myGreeting, 1200);
  //   function myGreeting() {
  //     document.getElementById("section1_pantyBKG").src = panty;
  //   }
  //   setTimeout(myGreeting2, 1200);
  //   function myGreeting2() {
  //     document.getElementById("panty__resp").src = pantyR;
  //   }
  // }, []);
  return (
    <div className="newsection1">
      <MediaQuery minWidth={1025}>
        <img
          id="section1_pantyBKG"
          className="section1_pantyBKG"
          src={panty}
          alt=""
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <img
          id="panty__resp"
          className="section1_pantyBKG"
          src={pantyR}
          alt=""
        />
      </MediaQuery>

      <Countdown
        timeTillDate="02 09 2022, 19:30 pm"
        timeFormat="MM DD YYYY, h:mm a"
      />
    </div>
  );
}

export default NewSection1;
